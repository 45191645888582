<template>
    <Loader v-if="loading"></Loader>
    <div class="bg-white card" v-else>
        <infinite-scroll-container class="" @load-next="loadNext">
        <div v-for="(order, index) in data" v-bind:key="index" class="cart-item fl-x-c mb-2 py-2 bb-1">
                        <div class="mr-2">
                            <img src="../../../assets/img/No_image_available.svg" :alt="order.name" v-if="!order.img"
                                 width="40px"/>
                            <img :src="order.img" alt="Image" class="order-img" height="40" width="40" v-else>
                        </div>
            <div class="fl-y-l fl-grow">
                <!--                 need to changes as name-->
                <b v-html="order.name"></b>
                <div class="fl-te-c">
                    <div class="fl-te-c">
                        <div>Quantity: {{ order.total_quantity }}
                            <b>
                                <div class="text-primary">Amount: ₹{{ order.total_amount }}</div>
                            </b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fl-y-c">
                <c-btn border-radius="0" color="info" icon="fa fa-eye" @click="orderDetails(order)"/>
            </div>

        </div>
        </infinite-scroll-container>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
import Loader from '../../../views/loader/loader';
import CardButton from '@components/CardButton';
import debounce from 'lodash.debounce';
import InfiniteScrollContainer from '@components/InfiniteScroll';

export default {
    name: 'ordersList',
    components: {
        Loader,
        InfiniteScrollContainer,
        'c-btn': CardButton
    },
    data () {
        return {
            loading: false,
            data: [],
            page: 0,
            isLast: false
        };
    },
    watch: {
        search: debounce(function () {
            this.data = [];
            this.page = 0;
            this.isLast = false;
            this.loadNext();
        }, 500)
    },
    beforeMount () {
        this.loadData();
    },
    methods: {
        async loadNext () {
            if (this.loading) {
                return;
            }
            if (this.isLast) {
                return;
            }
            this.page += 1;
            await this.loadData();
        },
        orderDetails (order) {
            const id = order.id;
            this.$router.push({ path: '/admin/orders/' + id + '/details/' });
        },
        async loadData () {
            this.loading = true;
            const response = await axios.get(urls.shopOrder.list, {
                params: {
                    status: 'all',
                    id: 1,
                    page: this.page,
                    per_page: 20
                }
            });
            this.loading = false;
            console.log('response', response.data.data);
            this.setData(response);
        },
        setData (response) {
            const items = response.data.data;
            if (items.length === 0) {
                this.isLast = true;
                return;
            }
            if (items) {
                this.data = [
                    ...this.data,
                    ...items
                ];
            }
        }
    }

};
</script>

<style scoped>

</style>
